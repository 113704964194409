<template>
  <div id="re-apply">
    <invoice-header text="电子发票详情"></invoice-header>

    <div class="rows">
      <span>重开类型</span>
      <span>重新选择行程</span>
    </div>
    <div class="rows" style="background:inherit;">
      <span>重开发票流程</span>
    </div>
    <div class="rows padding-left">
      <span>提交申请</span>
      <div class="line"></div>
    </div>
    <div class="rows padding-left">
      <span>审核通过</span>
    </div>
    <div class="rows padding-left">
      <span>返回按订单开票页</span>
    </div>
    <div class="rows padding-left">
      <span>重选行程开票</span>
    </div>

    <div class="text">
      <p>注意事项</p>
      <p>1.您提交的请求将由人工审核，人工审核的时间有可能比较长。</p>
      <p>2.审核通过后，该发票将作废，无法进行报销。</p>
      <p>3.审核通过后，该发票对应的订单将可以被重新选择并提交开票。</p>
    </div>

    <button @click="onSubmit">提交</button>
  </div>
</template>

<script>
import { reApply } from "./invoice";
import util from "../../commons/util";

export default {
  components: {
    "invoice-header": require("./header").default
  },
  created() {
    let InvoiceDetail = sessionStorage.getItem("InvoiceDetail");
    if (InvoiceDetail) {
      this.invoiceDetail = JSON.parse(InvoiceDetail);
    }
  },
  data() {
    return {
      invoiceDetail: null
    };
  },
  methods: {
    async onSubmit() {
      let res = await reApply(this.invoiceDetail.fpqqlsh);
      if (res.ret == "0") {
        util.toast(res.message);
      } else {
        sessionStorage.setItem(
          "ReturnInfo",
          JSON.stringify({
            page: "开票历史",
            routeName: "invoice_history"
          })
        );
        this.$router.push({ name: "invoice_order_success" });
      }
    }
  }
};
</script>

<style lang="scss">
#re-apply {
  min-height: 100vh;
  background: #f4f4f4;

  .rows {
    height: 0.8rem;
    margin: 0 auto;
    padding-left: 0.31rem;
    padding-right: 0.27rem;
    display: flex;
    align-items: center;
    font-size: 0.22rem;
    color: #333333;
    background-color: #fff;
    position: relative;
    border-bottom: 0.01rem solid #e6e6e6;

    &.padding-left {
      padding-left: 0.84rem;
    }

    .line {
      width: 0.67rem;
      height: 2.49rem;
      background-repeat: no-repeat;
      background-image: url("./images/line.png");
      background-size: contain;
      background-position-x: 0.31rem;
      position: absolute;
      top: 0.36rem;
      left: 0;
      z-index: 10;
      background-color: #fff;
    }

    span:nth-child(2) {
      margin-left: 0.3rem;
    }
  }

  .text {
    margin-top: 0.42rem;
    margin-bottom: 0.5rem;
    padding-left: 0.31rem;
    padding-right: 0.27rem;
    font-size: 0.2rem;
    color: #666666;
    p {
      line-height: 0.56rem;
    }
  }

  button {
    width: 91.667%;
    height: 0.8rem;
    margin: 0 auto;
    margin-left: 0.26rem;
    display: block;
    background-color: #20a3dc;
    border-radius: 0.06rem;
    border: none;
    outline: none;
    font-size: 0.36rem;
    color: #ffffff;
  }
}
</style>
